<template>
  <v-app id="signup">
    <v-main
      class="login-panel pt-10"
      :style="{ backgroundImage: `url(${backgroundUrl})` }"
    >
      <v-container class="fill-height">
        <v-snackbar v-model="snackbar" top right color="error">
          {{ textError }}
        </v-snackbar>
        <v-col cols="12">
          <v-row align="center" justify="center">
            <v-card class="py-8 px-md-5" width="600">
              <v-card-text>
                <h1
                  class="header-font blue-grey--text text--darken-2 font-weight-bold pb-4"
                >
                  Sign Up
                </h1>
                <h6 class="subtitle-1 pb-4 font-weight-light">
                  Do have an already account?
                  <a href="/"> Login </a>
                </h6>
                <v-form v-model="isFormValid">
                  <loading :active="isLoading" :loader="loader" />
                  <v-row no-gutters>
                    <v-col cols="12" sm="12" md="6 pr-md-2">
                      <v-text-field
                        type="text"
                        :rules="firstNameRules"
                        v-model.trim="signupData.firstName"
                        placeholder="First Name"
                        maxLength="70"
                        required
                        outlined
                        dense
                        append-icon="person"
                        :counter="maxCharacters"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        type="text"
                        :rules="lastNameRules"
                        v-model.trim="signupData.lastName"
                        placeholder="Last Name"
                        maxLength="70"
                        required
                        outlined
                        dense
                        append-icon="person"
                        :counter="maxCharacters"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        type="email"
                        v-model.trim="signupData.email"
                        :rules="emailRules"
                        placeholder="Email"
                        @input="inputed"
                        @keyup="enterClicked(signupData.email, $event)"
                        :hint="dynamicError"
                        required
                        outlined
                        dense
                      >
                        <template slot="append">
                          <v-icon
                            v-if="showIconEmailError"
                            class="menu-icon-error"
                            >cancel</v-icon
                          >
                          <v-icon
                            v-if="showIconEmailVerified"
                            class="menu-icon-verify"
                            >check_circle</v-icon
                          >
                          <v-icon v-if="showIconEmail">email</v-icon>
                        </template>
                        ></v-text-field
                      >
                    </v-col>

                    <v-col cols="12" sm="12" md="6 pr-md-2">
                      <v-select
                        :items="countryCodeOptions"
                        label="Country Code"
                        v-model="signupData.countryCode"
                        item-text="name"
                        item-value="code"
                        outlined
                        dense
                        required
                        :rules="countryCodeRules"
                      >
                        <template v-slot:item="data">
                          <div class="d-flex align-center">
                            <img
                              :src="getFlagURL(data.item.iso2)"
                              class="mr-2"
                              alt="flag"
                            />
                            <span>{{ data.item.name }}</span>
                            <span class="ml-auto">{{ data.item.code }}</span>
                          </div>
                        </template>
                        <template v-slot:selection="data">
                          <div class="d-flex align-center">
                            <img
                              :src="getFlagURL(data.item.iso2)"
                              class="mr-2"
                              alt="flag"
                            />
                            <span>{{ data.item.name }}</span>
                            <span class="ml-auto">{{ data.item.code }}</span>
                          </div>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        type="number"
                        :rules="phoneNumberRule"
                        v-model.trim="signupData.phoneNumber"
                        placeholder="Phone Number"
                        :counter="16"
                        append-icon="phone"
                        class="mb-0 pb-0"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6 pr-md-2">
                      <v-text-field
                        :rules="passwordRule"
                        hint="Password Must be contain at least 8 characters, including at least 1 number , 1 alphabet in lowercase letters or uppercase letters and 1 special characters e.g #, ?, !"
                        v-model.trim="signupData.password"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show2 ? 'text' : 'password'"
                        name="input-10-2"
                        placeholder="Password"
                        class="input-group--focused"
                        @click:append="show2 = !show2"
                        @input="(_) => (signupData.password = _)"
                        outlined
                        required
                        dense
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :rules="[confirmPasswordRules]"
                        v-model.trim="signupData.confirmPassword"
                        :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show3 ? 'text' : 'password'"
                        placeholder="Confirm Password"
                        required
                        outlined
                        dense
                        @click:append="show3 = !show3"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :rules="addressRule"
                        v-model.trim="signupData.address1"
                        counter="82"
                        maxLength="82"
                        placeholder="Address Line 1"
                        required
                        outlined
                        dense
                        append-icon="home"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :rules="addressRule2"
                        v-model.trim="signupData.address2"
                        counter="82"
                        maxLength="82"
                        placeholder="Address Line 2"
                        outlined
                        dense
                        append-icon="home_work"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6 pr-md-2">
                      <v-autocomplete
                        v-model.trim="signupData.country"
                        @change="getStateList(signupData.country)"
                        placeholder="Country"
                        :items="countryData"
                        :rules="countryRule"
                        required
                        outlined
                        dense
                        append-icon="business"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="6 ">
                      <v-autocomplete
                        v-if="showStateField"
                        v-model.trim="signupData.state"
                        :rules="stateRule"
                        @change="getProvinceCode(signupData.state)"
                        placeholder="State"
                        :items="stateData"
                        append-icon="location_on"
                        required
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="4 pr-md-2">
                      <v-text-field
                        v-model.trim="signupData.companyName"
                        @keyup="checkValidCompanyName"
                        :rules="companyNameRules"
                        placeholder="Company Name"
                        maxLength="70"
                        required
                        outlined
                        dense
                        :counter="maxCharacters"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4 pr-md-2">
                      <v-text-field
                        v-model.trim="signupData.taxNumber"
                        :rules="taxNumberRules"
                        @keypress="checkValidTaxNumber($event)"
                        placeholder="Tax number"
                        counter="18"
                        maxLength="18"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                      <v-text-field
                        :rules="vatNumberRules"
                        v-model.trim="signupData.vatNumber"
                        @keyup="checkValidVatNumber($event)"
                        placeholder="Vat number"
                        counter="18"
                        maxLength="18"
                        class="mb-0 pb-0"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6 pr-md-2">
                      <v-text-field
                        :rules="cityRule"
                        @keypress="checkCityCode($event)"
                        v-model.trim="signupData.city"
                        placeholder="City"
                        counter="15"
                        maxLength="15"
                        required
                        outlined
                        dense
                        append-icon="location_city"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6 pr-md-2">
                      <v-text-field
                        :rules="pincodeRule"
                        @keypress="checkValidPinCode($event)"
                        v-model.trim="signupData.pinCode"
                        placeholder="Pin Code"
                        counter="9"
                        maxLength="9"
                        required
                        outlined
                        dense
                        append-icon="pin_drop"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-btn
                color="primary"
                class="right-btn btn-loging"
                @click="createAccount"
                :disabled="!isFormValid"
                block
              >
                Sign Up
              </v-btn>
              <!-- <v-btn color="primary" class="px-0 mx-0 btn-loging" block href="/"
                >Login
              </v-btn> -->
              <!-- <v-row align="center" justify="center">
                  <v-btn color="error" to="/">CANCEL</v-btn>
                  <v-btn
                    color="primary"
                    class="right-btn"
                    to="/securityQuestion"
                    @click="createAccount"
                    :disabled="!isFormValid"
                  >
                    CREATE
                  </v-btn>
                </v-row> -->
            </v-card>
          </v-row>
        </v-col>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import authAPI from "@/services/authAPI.js";
import countryStateAPI from "@/services/countryStateAPI.js";
import backgroundUrl from "../../assets/images/didpanel.jpg";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import profileAPI from "@/services/profileAPI.js";
import {
  countryCodeOptions,
  getFlagURL,
} from "../ShoppingCart/countryCodeOptionsList";

export default {
  data() {
    return {
      isLoading: false,
      //fullPage: false,
      loader: "bars",
      snackbar: false,
      textError: "",
      dynamicError: "",
      show2: false,
      show3: false,
      valid: false,
      checkbox: true,
      countryCodeOptions: [],
      showIconEmail: true,
      showIconEmailVerified: false,
      showIconEmailError: false,
      showMessageSnackbar: false,
      showMessage: "",
      maxCharacters: 70,
      showStateField: Boolean,
      signupData: {
        postalAddress: {},
      },
      // firstNameRules
      firstNameRules: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length <= 70) || "Maximum 70 characters allowed",
        (v) =>
          /[^\w\s]/.test(v) ? "Special characters are not allowed" : true,
      ],
      lastNameRules: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length <= 70) || "Maximum 70 characters allowed",
        (v) =>
          /[^\w\s]/.test(v) ? "Special characters are not allowed" : true,
      ],
      emailRules: [
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "this value should be a valid email",
      ],
      firstName: "",
      lastName: "",
      // passwordRule
      passwordRule: [
        (value) => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
          return (
            pattern.test(value) ||
            "Min. 8 characters with at least one capital letter, a number and a special character."
          );
        },
      ],
      // confirmPasswordRules
      // confirmPasswordRules: [
      //   (value) => !!value || "Please enter confirm password",
      //   (value) =>
      //     value === this.signupData.password ||
      //     "The password confirmation does not match.",
      // ],
      // addressRule
      addressRule: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length <= 82) || "Maximum 82 characters allowed",

        (v) =>
          /^[a-zA-Z0-9,\-/. ]*$/.test(v) || "Only alphabets, numbers, '-', '/', ',' and '.' are allowed",
      ],
      addressRule2: [
        (v) => (!v || v.length <= 82) || "Maximum 82 characters allowed",
        (v) => (!v || /^[a-zA-Z0-9,\-/. ]+$/.test(v)) || "Only alphabets, numbers, '-', '/', ',' and '.' are allowed",
      ],


      // cityRule
      
      cityRule: [
        (v) => !!v || "this value is required",
        (v) => (v && v.length <= 15) || "Maximum 15 characters allowed",
        (v) =>
          /[^\w\s]/.test(v) ? "City name can only contain Letters" : true,
      ],
      countryRule: [
        (v) => !!v || "this value is required",
        (v) =>
          !!v ||
          /^[/_A-Za-z_ ()'-]+$/.test(v) ||
          "only alphabet, slash(/), apostrophe(), hyphens(-) and space are allowed in name field",
      ],
      stateRule: [
        (v) => !!v || "this value is required",
        (v) =>
          !!v ||
          /^[/_A-Za-z_ ()'-]+$/.test(v) ||
          "only alphabet, slash(/), apostrophe(), hyphens(-) and space are allowed in name field",
      ],

      // pincodeRule
      
      pincodeRule: [
        (v) => !!v || "this value is required",
        (v) =>
          /^[a-zA-Z0-9\-\s- ]{0,9}$/.test(v) ||
          "pin code can only contain numbers",
      ],
      // phoneNumberRule
      phoneNumberRule: [
        (v) =>
          (v && v.length >= 8) || "This field must have atleast 8 characters",
        (v) => (v && v.length <= 16) || "This field exceeds maximum 15 allowed",
      ],
      countryCodeRules: [
        (v) => !!v || "Field is required", // Check if the field is filled
        // (v) => v.length > 0 || "Please select a country code"  // Ensures a country code is selected
      ],
      companyNameRules: [],
      vatNumberRules: [],
      taxNumberRules: [],
      e1: true,
      loading: false,
      disabled: true,
      formValidated: "",
      countryData: [],
      stateData: [],
      countryGeoId: "",
      stateProvinceGeoId: "",
      isFormValid: false,
      backgroundUrl: "",
      UpdateLimitsData: {
        editData: {
          data: {
            twoway_trunks: 2,
            inbound_trunks: 0,
            outbound_trunks: 2,
            allow_prepay: true,
            ui_metadata: {
              version: "4.3-140",
              ui: "monster-ui",
              origin: "accounts",
            },
            authz_resource_types: [],
            burst_trunks: 0,
            enabled: true,
            max_postpay_ammount: 0.2,
            reserve_ammount: 0,
            soft_limit_inbound: false,
            soft_limit_outbound: false,
            allow_postpay: false,
            id: "limits",
            max_postpay_amount: 0,
          },
        },
      },
    };
  },

  components: {
    Loading,
  },
  mounted() {
    this.countryCodeOptions = countryCodeOptions;
    this.backgroundUrl = backgroundUrl;
    this.getCountryList();
  },
  methods: {
    getFlagURL,
    closeMessageDialog() {
      this.showMessageSnackbar = false;
    },

    async createAccount() {
      this.isLoading = true;
      try {
        this.signupData.countryGeoId = this.countryGeoId;
        this.signupData.stateProvinceGeoId = this.stateProvinceGeoId;
        this.signupData.email = this.signupData.email.toLowerCase();

        console.log("this.sdsdsadsa", this.signupData);
        let response = await authAPI.createAccount(this.signupData);
        localStorage.setItem("signupData", JSON.stringify(response));
        if (response) {
           localStorage.setItem(
            "auth",
            btoa(`${this.signupData.email}:${this.signupData.password}`));
           this.UpdateLimitsData.accountId = response.accountId;
           // get timezoe data
           let userData = {
           accountId: response.accountId,
           authToken: response.agentDetail.auth_token,
          };
           try {
            let response = await profileAPI.getAccountsDetails(userData);
            if (response) {
                this.UpdateLimitsData.editData.data = {
                ...response.accountdetails,
                ...this.UpdateLimitsData.editData.data,
              };
              this.updateLimits(response);
           }
          } catch (error) {
          console.log("====error===", error);
        }
          // localStorage.clear();
      }
        this.isLoading = false;
      } catch (error) {
        this.snackbar = true;
        this.textError = error.data.messageDetail;
        this.isLoading = false;
      }
    },

    // updateLimits API
    async updateLimits(response) {
      try {
        let responseUpdateLimits = await authAPI.updateLimits(
          this.UpdateLimitsData
        );
        if (responseUpdateLimits) {
          localStorage.removeItem("auth");
          setTimeout(() => {
            this.$router.push({
              name: "SecurityQuestion",
              params: {
                partyId: response.accountdetails.name,
                email: this.signupData.email,
              },
            });
          }, 500);
        }
      } catch (error) {
        this.snackbar = true;
        this.textError = error.data.messageDetail;
        this.isLoading = false;
      }
    },

    async getCountryList() {
      try {
        let response = await countryStateAPI.getCountryList();
        this.countryData = response.countryList;
      } catch (error) {}
    },

    async getStateList(country) {
      this.countryGeoId = country.split(": ")[1].trim();

      try {
        let response = await countryStateAPI.getStateList(this.countryGeoId);
        this.stateData = response.stateList;
        if (response.stateList == "No States/Provinces exist: _NA_") {
          this.showStateField = false;
          this.stateProvinceGeoI = null;
        } else {
          this.showStateField = true;
        }
        let stateFilterData = this.checkState(
          this.profileData.stateProvinceGeoId
        );
        this.profileData.state = stateFilterData[0];
      } catch (error) {}
    },

    getProvinceCode(state) {
      if (state) {
        this.stateProvinceGeoId = state.split(": ")[1].trim();
      }
    },

    async enterClicked(sEmail) {
      var validRegex = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (sEmail.match(validRegex)) {
        this.test(sEmail);
        return true;
      }
    },

    async test(re) {
      try {
        let response = await countryStateAPI.checkUserNameData(re);
        if (response.responseMessage == "success") {
          this.showIconEmailVerified = true;
          this.showIconEmailError = false;
          this.showIconEmail = false;
        }
        //this.stateData = response.stateList;
      } catch (error) {
        console.log("error", error);
        this.showIconEmailVerified = false;
        this.showIconEmailError = true;
        this.showIconEmail = false;
        this.snackbar = true;
        this.textError = error.data.messageDetail;
      }
    },

    inputed() {
      this.showIconEmail = true;
      this.showIconEmailVerified = false;
      this.showIconEmailError = false;
    },
    checkValidCompanyName() {
      if (this.signupData.companyName.length != 0) {
        this.companyNameRules = [
          (v) => (v && v.length <= 70) || "Maximum 70 characters allowed",
        ];
      } else {
        this.companyNameRules = [];
      }
    },
    checkValidVatNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      // if (/^[A-Za-z-0-9]+$/.test(char)) return true;
      // else e.preventDefault();
      if (
        this.signupData.vatNumber &&
        this.signupData.vatNumber &&
        this.signupData.vatNumber.length != 0
      ) {
        this.vatNumberRules = [
          (v) => (v && v.length <= 18) || "Maximum 18 characters allowed",
          (v) =>
            /[^\w\s]/.test(v) ? "Special characters are not allowed" : true,
        ];
      } else {
        this.vatNumberRules = [];
      }
    },
    checkValidTaxNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z-0-9]+$/.test(char)) return true;
      else e.preventDefault();
      if (this.signupData.taxNumber.length != 0) {
        this.taxNumberRules = [
          (v) => (v && v.length <= 18) || "Maximum 18 characters allowed",
        ];
      } else {
        this.taxNumberRules = [];
      }
    },
    checkValidPinCode(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
      if (this.signupData.pinCode.length != 0) {
        this.pincodeRule = [
          (v) => (v && v.length <= 9) || "Maximum 9 characters allowed",
        ];
      } else {
        this.taxNumberRules = [];
      }
    },
    checkCityCode(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
      if (this.signupData.city.length != 0) {
        this.cityRule = [
          (v) => (v && v.length <= 15) || "Maximum 15 characters allowed",
        ];
      } else {
        this.taxNumberRules = [];
      }
    },
  },
  computed: {
    confirmPasswordRules() {
      return (
        this.signupData.password === this.signupData.confirmPassword ||
        "Password must match"
      );
    },
  },
};
</script>

<style scoped>
/* .body-color {
  width: 100%;
  background-color: #f1f1f1;
  flex-wrap: wrap;
}
.signup-form {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  width: 800px;
}
.header {
  color: dodgerblue;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  display: block;
  text-align: center;
}
.form-field {
  display: -webkit-box;
}
.ajax-loader {
  padding-left: 36px;
  padding-right: 0;
}
.right-btn {
  margin-left: 2%;
} */
.btn-loging {
  margin-bottom: 15px;
}
.menu-icon-error {
  color: red;
}
.menu-icon-verify {
  color: greenyellow;
}
</style>
